@font-face {
  font-family: LINESeedKR;
  font-weight: 600;
  src:
    url(../fonts/eot/LINESeedKR-Bd.eot) format('embedded-opentype'),
    url(../fonts/woff/LINESeedKR-Bd.woff) format('woff'),
    url(../fonts/woff2/LINESeedKR-Bd.woff2) format('woff2');
}

@font-face {
  font-family: LINESeedKR;
  font-weight: 400;
  src:
    url(../fonts/eot/LINESeedKR-Rg.eot) format('embedded-opentype'),
    url(../fonts/woff/LINESeedKR-Rg.woff) format('woff'),
    url(../fonts/woff2/LINESeedKR-Rg.woff2) format('woff2');
}

@font-face {
  font-family: LINESeedKR;
  font-weight: 300;
  src:
    url(../fonts/eot/LINESeedKR-Th.eot) format('embedded-opentype'),
    url(../fonts/woff/LINESeedKR-Th.woff) format('woff'),
    url(../fonts/woff2/LINESeedKR-Th.woff2) format('woff2');
}
